import React, { Fragment, useContext, useEffect, useState } from 'react';
import SiteContext from '~/layouts/StoreContext';
import { Heading, BodyText, BodyTextAlt } from '~/components/.base/headings';
import { FlexBox } from '~/components/.base/containers';
import LineItem from '~/components/Checkout/CheckoutCart/LineItem';
import FallbackLineItem from './FallbackLineItem';
import './CartSummary.scss';
import SecureCheckoutIcon from '~/images/icons/checkout/secure_checkout.svg';

const CartSummary = ({
    fallbackLineItems,
    fallbackTotals,
    trueLineItemsSubtotalWithGiftCards,
    taxes,
    shipping,
    gcSum,
    gcTotal,
}) => {
    const {
        store: {
            checkout: { lineItems, subtotalPrice },
            trueLinePrices,
            trueLineItemsSubtotal,
            trueLineItemsBasePriceSubtotalNoDiscounts,
        },
    } = useContext(SiteContext);
    const [totalPrice, setTotalPrice] = useState(Number(subtotalPrice));
    useEffect(() => {
        if (trueLineItemsSubtotalWithGiftCards) {
            setTotalPrice(
                Number(trueLineItemsSubtotalWithGiftCards) + Number(shipping) + Number(taxes)
            );
        }
    }, [shipping, taxes, trueLineItemsSubtotalWithGiftCards]);
    return (
        <div className="cart-summary">
            <Heading>
                <span className="red">Order</span> summary
            </Heading>
            <ul className="cart-section line-items">
                {lineItems.length
                    ? lineItems.map((item, i) => (
                          <li key={i}>
                              <LineItem
                                  line_item={item}
                                  trueLinePrices={trueLinePrices}
                                  index={i}
                                  showQuantity={true}
                              />
                          </li>
                      ))
                    : fallbackLineItems.map((item, i) => (
                          <li key={i}>
                              <FallbackLineItem lineItem={item} index={i} />
                          </li>
                      ))}
            </ul>
            {lineItems.length ? (
                <Fragment>
                    {trueLineItemsBasePriceSubtotalNoDiscounts -
                    trueLineItemsSubtotalWithGiftCards ? (
                        <div className="cart-section totals">
                            <div className="total-item">
                                <BodyTextAlt>Items</BodyTextAlt>
                                <BodyTextAlt>
                                    {trueLineItemsBasePriceSubtotalNoDiscounts
                                        ? `$${Number(
                                              trueLineItemsBasePriceSubtotalNoDiscounts
                                          ).toFixed(2)}`
                                        : null}
                                </BodyTextAlt>
                            </div>
                            <div className="total-item savings">
                                <BodyTextAlt>Savings</BodyTextAlt>
                                <BodyTextAlt>
                                    {trueLineItemsBasePriceSubtotalNoDiscounts &&
                                    trueLineItemsSubtotal
                                        ? `$${Number(
                                              trueLineItemsBasePriceSubtotalNoDiscounts -
                                                  trueLineItemsSubtotal
                                          ).toFixed(2)}`
                                        : null}
                                </BodyTextAlt>
                            </div>
                            {gcSum ? (
                                <Fragment>
                                    <div className="total-item savings">
                                        <BodyTextAlt color="d4212c">Gift Card</BodyTextAlt>
                                        <BodyTextAlt color="d4212c">
                                            ${Number(gcSum).toFixed(2)}
                                        </BodyTextAlt>
                                    </div>
                                </Fragment>
                            ) : null}
                        </div>
                    ) : null}
                    <div className="cart-section totals">
                        <div className="total-item">
                            <BodyTextAlt>Subtotal</BodyTextAlt>
                            <BodyTextAlt>
                                {trueLineItemsSubtotalWithGiftCards
                                    ? `$${Number(trueLineItemsSubtotalWithGiftCards).toFixed(2)}`
                                    : null}
                            </BodyTextAlt>
                        </div>
                        <div className="total-item">
                            <BodyTextAlt>Taxes</BodyTextAlt>
                            <BodyTextAlt>${Number(taxes).toFixed(2)}</BodyTextAlt>
                        </div>
                        <div className="total-item">
                            <BodyTextAlt>Shipping</BodyTextAlt>
                            <BodyTextAlt>{`$${Number(shipping).toFixed(2)}`}</BodyTextAlt>
                        </div>
                    </div>
                    <div className="cart-section full-total">
                        <div style={{ marginBottom: '1.25rem' }}>
                            <div className="total-item">
                                <BodyTextAlt>Total</BodyTextAlt>
                                <BodyTextAlt>${Number(totalPrice).toFixed(2)}</BodyTextAlt>
                            </div>
                        </div>
                        <FlexBox align="center">
                            <SecureCheckoutIcon className="secure-checkout" />
                            <BodyText md="0 0 0 1rem">Secure checkout</BodyText>
                        </FlexBox>
                    </div>
                </Fragment>
            ) : (
                <Fragment>
                    {fallbackTotals &&
                    fallbackTotals.totalDiscountsSet &&
                    Number(fallbackTotals.totalDiscountsSet.shopMoney.amount) ? (
                        <div className="cart-section totals">
                            <div className="total-item">
                                <BodyTextAlt>Items</BodyTextAlt>
                                <BodyTextAlt>
                                    $
                                    {(
                                        Number(fallbackTotals.totalDiscountsSet.shopMoney.amount) +
                                        Number(fallbackTotals.subtotalPriceSet.shopMoney.amount)
                                    ).toFixed(2)}
                                </BodyTextAlt>
                            </div>
                            <div className="total-item savings">
                                <BodyTextAlt>Savings</BodyTextAlt>
                                <BodyTextAlt>
                                    $
                                    {Number(
                                        fallbackTotals.totalDiscountsSet.shopMoney.amount
                                    ).toFixed(2)}
                                </BodyTextAlt>
                            </div>
                        </div>
                    ) : null}
                    {fallbackTotals ? (
                        <Fragment>
                            <div className="cart-section totals">
                                <div className="total-item">
                                    <BodyTextAlt>Subtotal</BodyTextAlt>
                                    <BodyTextAlt>
                                        $
                                        {Number(
                                            fallbackTotals.subtotalPriceSet.shopMoney.amount
                                        ).toFixed(2)}
                                    </BodyTextAlt>
                                </div>
                                <div className="total-item">
                                    <BodyTextAlt>Taxes</BodyTextAlt>
                                    <BodyTextAlt>
                                        $
                                        {Number(
                                            fallbackTotals.totalTaxSet.shopMoney.amount
                                        ).toFixed(2)}
                                    </BodyTextAlt>
                                </div>
                                <div className="total-item">
                                    <BodyTextAlt>Shipping</BodyTextAlt>
                                    <BodyTextAlt>
                                        $
                                        {Number(
                                            fallbackTotals.totalShippingPriceSet.shopMoney.amount
                                        ).toFixed(2)}
                                    </BodyTextAlt>
                                </div>
                            </div>
                            <div className="cart-section full-total">
                                <div style={{ marginBottom: '1.25rem' }}>
                                    <div className="total-item">
                                        <BodyTextAlt>Total</BodyTextAlt>
                                        <BodyTextAlt>
                                            $
                                            {Number(
                                                fallbackTotals.totalPriceSet.shopMoney.amount
                                            ).toFixed(2)}
                                        </BodyTextAlt>
                                    </div>
                                </div>
                                <FlexBox align="center">
                                    <SecureCheckoutIcon className="secure-checkout" />
                                    <BodyText md="0 0 0 1rem">Secure checkout</BodyText>
                                </FlexBox>
                            </div>{' '}
                        </Fragment>
                    ) : null}
                </Fragment>
            )}
        </div>
    );
};
export default CartSummary;
