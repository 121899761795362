import React, { useContext, Fragment, useRef, useCallback } from 'react';
import Image from 'gatsby-image';
import { getImage } from '~/helpers';
import SiteContext from '~/layouts/StoreContext';
import { FlexBox, Grid, Row, Col, BackgroundDiv } from '~/components/.base/containers';
import { Heading, Subheading, BodyText } from '~/components/.base/headings';
import { CheckoutInput } from '~/components/.base/inputs';
import { WhiteButton } from '~/components/.base/buttons';
import CheckIcon from '~/images/icons/checkout/checkbox.svg';
import './OrderDetails.scss';
import { addFulfillmentNumber } from '~/helpers/requests/checkout-requests';

const OrderDetails = ({
    billingAddress,
    fallbackShippingAddress,
    fulfillmentNumber,
    setFulfillmentNumber,
    fulfillmentMessage,
    setFulfillmentMessage,
    bgImages,
    orderName,
    userEmail,
    deliveryDate,
    onlyGCs,
    hasSignedUp,
    prevOrderId,
    prevOrderNote,
    setHasSignedUp,
}) => {
    const {
        store: {
            checkout: { shippingAddress },
        },
    } = useContext(SiteContext);

    const phoneFieldRef = useRef(null);

    const formatDate = date => {
        const dateComps = new Date(date).toString().split(' ').slice(1, 4);
        const months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        if (!dateComps[0] || !dateComps[1] || !dateComps[2] || !months.includes(dateComps[0])) {
            return null;
        }
        // return null;
        return dateComps[0] + '. ' + dateComps[1] + ', ' + dateComps[2];
    };

    /* const signUpForFulfillmentTexts = () => {
        addFulfillmentNumber(prevOrderId, prevOrderNote, fulfillmentNumber)
            .then(res => {
                console.log(res);
                if (res.data.status) {
                    if (res.data.code === 21211) {
                        setFulfillmentMessage(
                            'Your phone number is not properly formatted. Please format your phone number in the following manner: XXX-XXX-XXXX'
                        );
                        return;
                    }
                }
                if (!res.data.data.orderUpdate.userErrors.length) {
                    setHasSignedUp(true);
                    setFulfillmentMessage(
                        'You have successfully signed up for text notifications!'
                    );
                } else {
                    setFulfillmentMessage(res.data.data.orderUpdate.userErrors[0]);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }; */

    const handleSignUpForFulfillmentTexts = () => {
        addFulfillmentNumber(prevOrderId, prevOrderNote, fulfillmentNumber)
            .then(res => {
                console.log(res);
                if (res.data.status) {
                    if (res.data.code === 21211) {
                        setFulfillmentMessage(
                            'Your phone number is not properly formatted. Please format your phone number in the following manner: [+][country code][subscriber number including area code]'
                        );
                        return;
                    }
                }
                if (!res.data.data.orderUpdate.userErrors.length) {
                    setHasSignedUp(true);
                    setFulfillmentMessage(
                        'You have successfully signed up for text notifications!'
                    );
                } else {
                    setFulfillmentMessage(res.data.data.orderUpdate.userErrors[0]);
                }
            })
            .catch(err => {
                console.log(err);
            });
        if (!hasSignedUp && phoneFieldRef?.current) phoneFieldRef.current.focus();
    };

    /* const handleNumberChange = useCallback(
        value => {
            const numbers = value.replaceAll('-', '');
            if (value.length > 7) {
                if (value.slice(-1) === '-') {
                    setFulfillmentNumber(value.slice(0, -1));
                    return;
                }
                setFulfillmentNumber(numbers.replace(/(\d{3})(\d{3})(\.*)/, '$1-$2-$3'));
                return;
            }
            if (value.length > 3) {
                if (value.slice(-1) === '-') {
                    setFulfillmentNumber(value.slice(0, -1));
                    return;
                }
                setFulfillmentNumber(numbers.replace(/(\d{3})(\d{1})/, '$1-$2'));
                return;
            }
            setFulfillmentNumber(value);
        },
        [phoneFieldRef]
    ); */

    /* const handleNumberKeyPress = e => {
        if (isNaN(e.key)) e.preventDefault();
    }; */

    return (
        <div className="order-details">
            <BackgroundDiv w="70%" left="0" top="0" z="-1">
                <Image fluid={getImage(bgImages, 'blurred_smudge')} alt="" />
            </BackgroundDiv>
            <FlexBox className="order-confirm" justify="space-between">
                <FlexBox>
                    <div className="green-check">
                        <CheckIcon />
                    </div>
                    <Heading md=".5rem  0 0 20px">
                        {billingAddress && billingAddress.firstName
                            ? `${billingAddress.firstName}, y`
                            : 'Y'}
                        our order is <br /> <span className="red">confirmed!</span>
                    </Heading>
                </FlexBox>
                <FlexBox className="order-numbs" align="center">
                    {orderName ? (
                        <div>
                            <Subheading>Order Number</Subheading>
                            <BodyText md="0">{orderName}</BodyText>
                        </div>
                    ) : null}
                    {deliveryDate && formatDate(deliveryDate) ? (
                        <div>
                            <Subheading>Delivery Date</Subheading>
                            <BodyText md="0">{formatDate(deliveryDate)}</BodyText>
                        </div>
                    ) : null}
                </FlexBox>
            </FlexBox>
            <Grid className="order-specifics">
                {!onlyGCs && (
                    <Row>
                        <Col size={2}>
                            <Subheading>Shipping Address</Subheading>
                        </Col>
                        <Col size={3}>
                            {shippingAddress ? (
                                <>
                                    <FlexBox justify="space-between">
                                        <BodyText md="5px 0 37px">
                                            {shippingAddress.firstName} {shippingAddress.lastName}
                                            <br />
                                            {shippingAddress.address1}
                                            <br />
                                            {shippingAddress.address2 &&
                                            shippingAddress.address2.length ? (
                                                <Fragment>
                                                    {shippingAddress.address2}
                                                    <br />
                                                </Fragment>
                                            ) : null}
                                            {shippingAddress.city}, {shippingAddress.provinceCode}{' '}
                                            {shippingAddress.zip}
                                            <br />
                                            {shippingAddress.country}
                                        </BodyText>
                                    </FlexBox>
                                    {/* Fix accessible */}
                                    {/* <iframe
                                          title="shipping-address"
                                          className="address-embed"
                                          frameborder="0"
                                          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBhG2W1tn5AUfDsx3u1OLm24_4flLCUtqA&q=${
                                            shippingAddress.address1
                                          },${
                                            shippingAddress.address2
                                              ? shippingAddress.address2 + ","
                                              : ""
                                          }${shippingAddress.city},${shippingAddress.province},${
                                            shippingAddress.zip
                                          },${shippingAddress.country}`}
                                          allowfullscreen
                                        ></iframe> */}
                                </>
                            ) : fallbackShippingAddress ? (
                                <>
                                    <FlexBox justify="space-between">
                                        <BodyText md="5px 0 37px">
                                            {fallbackShippingAddress.firstName}{' '}
                                            {fallbackShippingAddress.lastName}
                                            <br />
                                            {fallbackShippingAddress.address1}
                                            <br />
                                            {fallbackShippingAddress.address2 &&
                                            fallbackShippingAddress.address2.length ? (
                                                <Fragment>
                                                    {fallbackShippingAddress.address2}
                                                    <br />
                                                </Fragment>
                                            ) : null}
                                            {fallbackShippingAddress.city},{' '}
                                            {fallbackShippingAddress.provinceCode}{' '}
                                            {fallbackShippingAddress.zip}
                                            <br />
                                            {fallbackShippingAddress.country}
                                        </BodyText>
                                    </FlexBox>
                                    {/* Fix accessible */}
                                    {/* <iframe
                                        title="shipping-address"
                                        className="address-embed"
                                        frameborder="0"
                                        src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBhG2W1tn5AUfDsx3u1OLm24_4flLCUtqA&q=${
                                          fallbackShippingAddress.address1
                                        },${
                                          fallbackShippingAddress.address2
                                            ? fallbackShippingAddress.address2 + ","
                                            : ""
                                        }${fallbackShippingAddress.city},${
                                          fallbackShippingAddress.provinceCode
                                        },${fallbackShippingAddress.zip},${
                                          fallbackShippingAddress.countryCodeV2
                                        }`}
                                        allowfullscreen
                                      ></iframe> */}
                                </>
                            ) : null}
                        </Col>
                    </Row>
                )}
                <Row className="sms-updates">
                    <Col size={2}>
                        <Subheading>Order Updates</Subheading>
                        <BodyText>
                            Sign up for "Shipment Updates via Text" to receive an SMS text message
                            when your package is out for delivery, delivered, or encounters a
                            problem. If this is a gift, you can enter the recipient's number so they
                            receive mobile shipping updates.
                        </BodyText>
                    </Col>
                    <Col size={3}>
                        <FlexBox align="flex-end">
                            <CheckoutInput h="40px" md="0px">
                                <label htmlFor="phone">Mobile Number</label>
                                <input
                                    id="phone"
                                    name="phone"
                                    type="tel"
                                    placeholder="Enter Number Here"
                                    onChange={e => setFulfillmentNumber(e.target.value)}
                                    // onKeyPress={e => handleNumberKeyPress(e)}
                                    value={fulfillmentNumber}
                                    ref={phoneFieldRef}
                                    aria-describedby="phoneError"
                                    autoComplete="tel"
                                />
                            </CheckoutInput>
                            <WhiteButton pd="0 35px" onClick={handleSignUpForFulfillmentTexts}>
                                Sign Up
                            </WhiteButton>
                        </FlexBox>

                        <BodyText id="phoneError" md=".5rem 0" color="#d4212c">
                            {!hasSignedUp && fulfillmentMessage}
                        </BodyText>
                        {hasSignedUp &&
                            (fulfillmentMessage ? (
                                <BodyText md=".5rem 0" color="#d4212c">
                                    {fulfillmentMessage}
                                </BodyText>
                            ) : null)}
                    </Col>
                </Row>
                <Row>
                    <Col size={2}>
                        <Subheading>Confirmation will be sent to</Subheading>
                    </Col>
                    <Col size={3}>
                        <BodyText>{userEmail}</BodyText>
                    </Col>
                </Row>
                {formatDate(new Date()) && (
                    <Row>
                        <Col size={2}>
                            <Subheading>Order Date</Subheading>
                        </Col>
                        <Col size={3}>
                            <BodyText>{formatDate(new Date())}</BodyText>
                        </Col>
                    </Row>
                )}
                {!onlyGCs && (
                    <Row>
                        <Col size={2}>
                            <Subheading>Shipping Method</Subheading>
                        </Col>
                        <Col size={3}>
                            <BodyText>UPS Next Day Air</BodyText>
                        </Col>
                    </Row>
                )}
            </Grid>
            <FlexBox className="order-contact" justify="space-between" align="center">
                <Subheading md="0 1rem 0 0">We're here for you</Subheading>
                <BodyText md="0 1rem">Feel free to drop a line if you have any questions.</BodyText>
                <WhiteButton as="a" href="mailto:orders@cousinsmainelobster.com">
                    Contact Us
                </WhiteButton>
            </FlexBox>
        </div>
    );
};

export default OrderDetails;
