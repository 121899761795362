import React, { useEffect, useState, useContext } from 'react';
import { graphql } from 'gatsby';
import { getImage } from '~/helpers';
import {
  createShopifyOrder,
  completeShopifyOrder,
  getOrderData,
  // addFulfillmentNumber,
} from '~/helpers/requests/checkout-requests';
import {
  getUpsells,
  getShopifyProduct,
  submitUpsellPayment,
} from '~/helpers/requests/upsell-requests';
import SiteContext from '~/layouts/StoreContext';
import { Criteo } from '~/components/Criteo/Criteo';
import SEO from '../components/seo';
import { DeriveHeaderClasses } from '~/components/Navigation';
import { CartSummary, OneClickUpsells, OrderDetails } from '~/components/OrderConfirmPage';
import { Grid, Row, Col } from '~/components/.base/containers';
import './order-completed.scss';

const OrderPage = ({ data, location }) => {
  const {
    store: {
      checkout: { lineItems, shippingAddress },
      trueLineItemsSubtotal,
    },
  } = useContext(SiteContext);
  const [upsells, setUpsells] = useState([]);
  const [shipByDate, setShipByDate] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [userEmail, setUserEmail] = useState('');
  const [orderName, setOrderName] = useState(null);
  const [stripeId, setStripeId] = useState();
  const [gcSum, setGcSum] = useState(undefined);
  const [gcTotal, setGcTotal] = useState(undefined);
  const [onlyGCs, setOnlyGCs] = useState(false);
  const [noStripeId, setNoStripeId] = useState(false);
  const [taxes, setTaxes] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState('');
  const [trueLineItemsSubtotalWithGiftCards, setTrueLineItemsSubtotalWithGiftCards] = useState(0);

  const [fallbackLineItems, setFallbackLineItems] = useState([]);
  const [fallbackShippingAddress, setFallbackShippingAddress] = useState(null);
  const [billingAddress, setBillingAddress] = useState(null);
  const [fallbackTotals, setFallbackTotals] = useState(null);

  const [prevOrderId, setPrevOrderId] = useState(null);
  const [prevOrderNote, setPrevOrderNote] = useState(null);
  const [hasSignedUp, setHasSignedUp] = useState(null);
  const [fulfillmentNumber, setFulfillmentNumber] = useState('');
  const [fulfillmentMessage, setFulfillmentMessage] = useState(null);
  const [readyToTrack, setReadyToTrack] = useState(false);
  const [hasNotFiredFacebookPurchase, setHasNotFiredFacebookPurchase] = useState(true);
  const [hasNotFiredGoogleConversion, setHasNotFiredGoogleConversion] = useState(true);
  const [hasNotFiredAttentivePurchase, setHasNotFiredAttentivePurchase] = useState(true);
  const [readyToFireCriteo, setReadyToFireCriteo] = useState(false);
  useEffect(() => {
    if (window && window.ga && readyToTrack && fallbackTotals?.totalPriceSet?.shopMoney?.amount) {
      window.ga('require', 'ec');
      for (let lineItem of fallbackLineItems) {
        window.ga('ec:addProduct', {
          name: lineItem.node.name,
          id: lineItem.node.id,
          price: Number(lineItem.node.originalTotalSet.shopMoney.amount).toFixed(2),
          brand: 'CML',
          variant: lineItem.node.variantTitle,
          quantity: lineItem.node.quantity,
        });
      }
      // Add the step number and additional info about the checkout to the action.
      console.log('firing ga purchase event ', {
        revenue: Number(fallbackTotals.subtotalPriceSet.shopMoney.amount).toFixed(2),
        tax: Number(taxes).toFixed(2), // Tax (number).
        shipping: Number(shipping).toFixed(2), // Shipping (number).
      });
      window.ga('ec:setAction', 'purchase', {
        // Transaction details are provided in an actionFieldObject.
        id: localStorage.getItem('shopify_checkout_id'), // (Required) Transaction id (string).
        revenue: Number(fallbackTotals.subtotalPriceSet.shopMoney.amount).toFixed(2),
        tax: Number(taxes).toFixed(2), // Tax (number).
        shipping: Number(shipping).toFixed(2), // Shipping (number).
      });
      window.ga('send', 'pageview');
    }
  }, [lineItems, fallbackTotals, taxes, shipping, readyToTrack]);
  useEffect(() => {
    if (
      window &&
      window.gtag &&
      readyToTrack &&
      fallbackTotals?.totalPriceSet?.shopMoney?.amount &&
      hasNotFiredGoogleConversion
    ) {
      console.log(
        'fire conversion google',
        Number(fallbackTotals.totalPriceSet.shopMoney.amount).toFixed(2)
      );
      window.gtag('event', 'conversion', {
        send_to: 'AW-764617497/9DitCLanvtsCEJnGzOwC',
        value: Number(fallbackTotals.totalPriceSet.shopMoney.amount).toFixed(2),
        currency: 'USD',
        transaction_id: prevOrderId,
      });
      setHasNotFiredGoogleConversion(false);
    }
    if (
      window &&
      window.fbq &&
      readyToTrack &&
      fallbackTotals?.totalPriceSet?.shopMoney?.amount &&
      hasNotFiredFacebookPurchase
    ) {
      console.log(
        'fire purchase facebook',
        Number(fallbackTotals.totalPriceSet.shopMoney.amount).toFixed(2)
      );
      window.fbq('track', 'Purchase', {
        value: Number(fallbackTotals.totalPriceSet.shopMoney.amount).toFixed(2),
        currency: 'USD',
      });
      setHasNotFiredFacebookPurchase(false);
    }
    if (
      window &&
      window.attentive &&
      readyToTrack &&
      hasNotFiredAttentivePurchase &&
      fallbackLineItems?.length
    ) {
      console.log('fire purchase attentive', {
        items: fallbackLineItems.map(lineItem => {
          return {
            productId: lineItem.node.product.id,
            productVariantId: lineItem.node.variant.id,
            name: lineItem.node.name,
            productImage: lineItem.node.image.originalSrc,
            category: 'CML',
            price: {
              value: Number(lineItem.node.originalTotalSet.shopMoney.amount).toFixed(2),
              currency: 'USD',
            },
            quantity: lineItem.node.quantity,
          };
        }),
        order: {
          orderId: prevOrderId,
        },
      });
      try {
        window.attentive.analytics.purchase({
          items: fallbackLineItems.map(lineItem => {
            return {
              productId: lineItem.node.product.id,
              productVariantId: lineItem.node.variant.id,
              name: lineItem.node.name,
              productImage: lineItem.node.image.originalSrc,
              category: 'CML',
              price: {
                value: Number(lineItem.node.originalTotalSet.shopMoney.amount).toFixed(2),
                currency: 'USD',
              },
              quantity: lineItem.node.quantity,
            };
          }),
          order: {
            orderId: prevOrderId,
          },
        });
      } catch (err) {
        console.log(err);
      }
      setHasNotFiredAttentivePurchase(false);
    }
  }, [fallbackTotals, prevOrderId, readyToTrack]);
  useEffect(() => {
    if (typeof window == 'undefined') return;
    if (document) {
      if (document.querySelector('#launcher')) {
        document.querySelector('#launcher').style.display = 'none';
      }
    }
  }, []);
  useEffect(() => {
    (async () => {
      const params = new URLSearchParams(window.location.search);
      setUserEmail(params.get('u').replace(' ', '+').replace(' ', '+').replace(' ', '+'));
      setStripeId(params.get('sid'));
      setGcSum(params.get('gc_sum'));
      setGcTotal(params.get('gc_total'));
      setOnlyGCs(params.get('only_gcs') === 'true');
      setNoStripeId(params.get('sid') === 'undefined' || !params.get('sid'));
      if (params.get('gc_sum')) {
        setTrueLineItemsSubtotalWithGiftCards(trueLineItemsSubtotal - Number(params.get('gc_sum')));
      } else {
        setTrueLineItemsSubtotalWithGiftCards(trueLineItemsSubtotal);
      }
      setTaxes(params.get('t') ? params.get('t') : '0');
      setShipping(params.get('s') ? params.get('s') : '0');
      try {
        const orderData = await getOrderData(params.get('oid'));
        const {
          name,
          id,
          email,
          note,
          shippingAddress,
          billingAddress,
          subtotalPriceSet,
          totalDiscountsSet,
          totalPriceSet,
          totalShippingPriceSet,
          totalTaxSet,
        } = orderData.data.data.order;
        const orderLineItems = orderData.data.data.order.lineItems;
        setOrderName(name);
        setPrevOrderId(id);
        setPrevOrderNote(note);
        setBillingAddress(billingAddress);
        setFallbackTotals({
          subtotalPriceSet,
          totalDiscountsSet,
          totalShippingPriceSet,
          totalTaxSet,
          totalPriceSet,
        });
        setFallbackLineItems(orderLineItems.edges);
        if (!params.get('u') || params.get('u') === 'undefined') {
          setUserEmail(email);
        }
        if (lineItems.length) {
          localStorage.removeItem('shopify_checkout_id');
        } else if (params.get('oid')) {
          setFallbackShippingAddress(shippingAddress);
        } else {
          window.location.href = '/';
        }
        if (params.get('only_gcs') === 'true') {
          /* console.log(orderLineItems);
                    const gcLineItem = orderLineItems.edges.find(
                        item => item.node.name && item.node.name.includes('Gift Card')
                    );
                    console.log('gcLineItem', gcLineItem);
                    if (gcLineItem) {
                        const deliveryDateAttr = gcLineItem.node.customAttributes.find(
                            attr => attr.key === 'delivery_date'
                        );
                        console.log('deliveryDateAttr', deliveryDateAttr);
                        if (deliveryDateAttr) {
                            const shipBy = new Date(deliveryDateAttr.value);
                            shipBy.setUTCHours(18);
                            shipBy.setDate(Number(deliveryDateAttr.value.split('-')[1]));
                            setShipByDate(shipBy);
                            const delivery = new Date(deliveryDateAttr.value);
                            delivery.setUTCHours(18);
                            delivery.setDate(Number(deliveryDateAttr.value.split('-')[1]));
                            setDeliveryDate(delivery);
                        }
                    } */
        } else {
          let shipByStr = note.split('__SHIPBY__')[1];
          shipByStr = `${shipByStr.split('-')[2]}-${shipByStr.split('-')[0]}-${
            shipByStr.split('-')[1]
          }`;
          const shipBy = new Date(shipByStr);
          shipBy.setUTCHours(18);
          shipBy.setDate(Number(shipByStr.split('-')[2]));
          setShipByDate(shipBy);
          const delivery = new Date(shipByStr);
          delivery.setUTCHours(18);
          delivery.setDate(Number(shipByStr.split('-')[2]) + 1);
          setDeliveryDate(delivery);
        }
        setReadyToTrack(true);
        setTimeout(() => setReadyToFireCriteo(true), 1500);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [lineItems, trueLineItemsSubtotal]);
  useEffect(() => {
    (async () => {
      if (fallbackTotals?.totalPriceSet) {
        const upsellRes = await getUpsells('Checkout');
        var upsellArr = [];
        for (let upsell of upsellRes.data) {
          if (
            upsell.order_value_threshold < Number(fallbackTotals?.totalPriceSet?.shopMoney?.amount)
          ) {
            const upsellDataRes = await getShopifyProduct(upsell.upsell_handle);
            const productData = upsellDataRes?.data?.data;

            if (productData && productData?.productByHandle?.totalInventory > 0) {
              upsellArr.push({
                ...productData.productByHandle,
                upsell_type: upsell.upsell_type,
                discount_amount: upsell.discount_amount,
                has_purchased: false,
                is_loading: false,
              });
            }
          }
        }
        setUpsells(upsellArr);
      }
    })();
  }, [fallbackTotals]);

  const createShopifyOrderFn = async (varId, savings, values) => {
    const trueShipping = 0,
      lineItems = [
        {
          variant: {
            id: varId,
          },
          quantity: 1,
          customAttributes: [
            {
              key: 'order_type',
              value: 'upsell',
            },
          ],
        },
      ],
      discountApplications = [
        {
          value: {
            amount: `${savings.toFixed(2)}`,
            title: 'Upsell Discount',
          },
          targetType: 'FIXED_AMOUNT',
        },
      ];
    let customer, lineItemsSubtotalPrice, subtotalPrice;
    values.email = userEmail;
    if (hasSignedUp) {
      values.fulfillmentNumber = fulfillmentNumber;
    }
    const res = await createShopifyOrder(
      values,
      customer,
      lineItems,
      trueShipping,
      discountApplications,
      prevOrderNote.split('__SHIPBY__')[1],
      prevOrderNote.split('__DELIVERBY__')[1]
    );
    console.log('DRAFTORDERCREATE RES: ', res);
    return res.data.data.draftOrderCreate.draftOrder;
  };
  const createUpsellOrder = async (varId, savings, idx) => {
    console.log(shippingAddress);
    const trueShippingAddress = shippingAddress ? shippingAddress : fallbackShippingAddress;
    setUpsells(upsells =>
      upsells.map((u, i) => {
        if (i === idx) {
          return { ...u, is_loading: true };
        }
        return u;
      })
    );
    try {
      const createdOrder = await createShopifyOrderFn(varId, savings, trueShippingAddress);
      console.log('CREATED ORDER: ', createdOrder);
      const payment = await submitUpsellPayment(stripeId, createdOrder.totalPrice, createdOrder.id);
      console.log(payment);
      if (payment?.data?.error) {
        console.log('error: ', payment.data.error);
        throw new Error(payment.data.error.message);
        // Display error.message in your UI.
      } else {
        console.log('yay!!!');
        await completeShopifyOrder(createdOrder.id);
        setUpsells(upsells =>
          upsells.map((u, i) => {
            if (i === idx) {
              return {
                ...u,
                is_loading: false,
                has_purchased: true,
              };
            }
            return u;
          })
        );
        if (window.fbq) {
          window.fbq('track', 'Purchase', {
            value: Number(createdOrder.totalPrice),
            currency: 'USD',
          });
        }
      }
    } catch (err) {
      console.log(err);
      setPaymentErrorMessage(err.message);
      setTimeout(() => setPaymentErrorMessage(''), 10000);
    }
  };
  /* const signUpForFulfillmentTexts = () => {
        addFulfillmentNumber(prevOrderId, prevOrderNote, fulfillmentNumber)
            .then(res => {
                console.log(res);
                if (res.data.status) {
                    if (res.data.code === 21211) {
                        setFulfillmentMessage(
                            'Your phone number is not properly formatted. Please format your phone number in the following manner: XXX-XXX-XXXX'
                        );
                        return;
                    }
                }
                if (!res.data.data.orderUpdate.userErrors.length) {
                    setHasSignedUp(true);
                    setFulfillmentMessage(
                        'You have successfully signed up for text notifications!'
                    );
                } else {
                    setFulfillmentMessage(res.data.data.orderUpdate.userErrors[0]);
                }
            })
            .catch(err => {
                console.log(err);
            });
    }; */
  return (
    <Grid className="order-confirm-page">
      <SEO title="Order Complete" />
      <Criteo
        type="orderConfirm"
        orderId={prevOrderId}
        lineItems={fallbackLineItems}
        readyToFireCriteo={readyToFireCriteo}
      />
      <DeriveHeaderClasses location={location} />
      <div>
        <div
          className="banner"
          style={{
            backgroundImage: `url("${getImage(data.imageData.edges, 'order_confirm_banner').src}"
					)`,
          }}
        ></div>
      </div>
      <Row className="order-confirm-row">
        <Col size={4}>
          <OrderDetails
            userEmail={userEmail}
            deliveryDate={deliveryDate}
            orderName={orderName}
            bgImages={data.bgImageData.edges}
            billingAddress={billingAddress}
            fallbackShippingAddress={fallbackShippingAddress}
            prevOrderId={prevOrderId}
            prevOrderNote={prevOrderNote}
            setHasSignedUp={setHasSignedUp}
            // signUpForFulfillmentTexts={signUpForFulfillmentTexts}
            fulfillmentNumber={fulfillmentNumber}
            setFulfillmentNumber={setFulfillmentNumber}
            fulfillmentMessage={fulfillmentMessage}
            setFulfillmentMessage={setFulfillmentMessage}
            hasSignedUp={hasSignedUp}
            onlyGCs={onlyGCs}
          />
        </Col>
        <Col size={2}>
          <CartSummary
            fallbackTotals={fallbackTotals}
            fallbackLineItems={fallbackLineItems}
            trueLineItemsSubtotalWithGiftCards={trueLineItemsSubtotalWithGiftCards}
            taxes={taxes}
            shipping={shipping}
            gcSum={gcSum}
            gcTotal={gcTotal}
          />
          {!onlyGCs && !noStripeId ? (
            <OneClickUpsells
              upsells={upsells}
              createUpsellOrder={createUpsellOrder}
              paymentErrorMessage={paymentErrorMessage}
            />
          ) : null}
        </Col>
      </Row>
    </Grid>
  );
};

export const query = graphql`
  query {
    imageData: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/orderconfirm/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1600, quality: 70) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    bgImageData: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        absolutePath: { regex: "/images/background/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 1600, quality: 70) {
              aspectRatio
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
export default OrderPage;
