import React from 'react';
import { FlexBox } from '~/components/.base/containers';
import { BodyText, BodyTextAlt } from '~/components/.base/headings';
import { getShopifyImageTransformedSrc } from '../../../helpers';

const FallbackLineItem = ({ lineItem, index }) => {
    const { image, name, originalTotalSet, quantity } = lineItem.node;
    const variantImage = image ? (
        <img
            src={getShopifyImageTransformedSrc(image.originalSrc, '_65x65_crop_center')}
            alt=""
            height="65px"
            width="65px"
        />
    ) : null;
    return (
        <FlexBox
            align="center"
            justify="space-between"
            className="d-flex align-center flex-around line-item"
        >
            {' '}
            <FlexBox align="center">
                <div className="img-cont">{variantImage}</div>
                <BodyText md="0 0 0 1rem">
                    {name} x {quantity}
                </BodyText>
            </FlexBox>
            <div className="line-item-price tr">
                <BodyTextAlt md="0 0 0 1rem" align="right">
                    ${Number(originalTotalSet.shopMoney.amount).toFixed(2)}
                </BodyTextAlt>
            </div>
        </FlexBox>
    );
};

export default FallbackLineItem;
