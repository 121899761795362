import React, { Fragment } from 'react';
import Loader from 'react-loader-spinner';
import { getShopifyImageTransformedSrc } from '../../../helpers';

import { Heading, BodyText, BodyTextAlt } from '~/components/.base/headings';
import { FlexBox } from '~/components/.base/containers';
import { RedButton } from '~/components/.base/buttons';
import './OneClickUpsells.scss';

const OneClickUpsells = ({ upsells, createUpsellOrder, paymentErrorMessage }) => {
    if (upsells.length) {
        return (
            <div className="one-click-upsells">
                <Heading md="0 0 1rem">
                    You're <span className="red">one click away!</span>
                </Heading>
                <BodyText>
                    You can add these products to your order with just one click. We'll process the
                    payment to your credit card with no new shipping costs.
                </BodyText>
                {paymentErrorMessage.length ? (
                    <BodyText color="#d4212c">{paymentErrorMessage}</BodyText>
                ) : null}
                {upsells?.length > 0 && (
                    <ul className="one-click-upsells-items">
                        {upsells.map((u, i) => {
                            var price,
                                compareAtPrice = Number(u.variants.edges[0].node.priceV2.amount);
                            if (u.upsell_type === 'Percent Discount') {
                                price = (compareAtPrice * (100 - Number(u.discount_amount))) / 100;
                            } else {
                                price = compareAtPrice - Number(u.discount_amount);
                            }
                            const savings = compareAtPrice - price;
                            return (
                                <FlexBox
                                    key={i}
                                    as="li"
                                    align="center"
                                    justify="space-between"
                                    className="upsell-item"
                                >
                                    <FlexBox align="center">
                                        <div className="img-cont">
                                            {
                                                <img
                                                    src={
                                                        u.media.edges.length
                                                            ? getShopifyImageTransformedSrc(
                                                                  u.media.edges[0].node.previewImage
                                                                      .originalSrc,
                                                                  '_300x'
                                                              )
                                                            : ''
                                                    }
                                                    alt=""
                                                />
                                            }
                                        </div>
                                        <div>
                                            <BodyText md="0 1rem 6px">{u.title}</BodyText>
                                            <BodyTextAlt md="0 1rem 0">
                                                {`$${price.toFixed(2)}`}
                                            </BodyTextAlt>
                                        </div>
                                    </FlexBox>
                                    {u.has_purchased ? (
                                        <BodyTextAlt className="added-text" md="0" color="#d4212c">
                                            Item Added!
                                        </BodyTextAlt>
                                    ) : (
                                        <RedButton
                                            onClick={() =>
                                                createUpsellOrder(
                                                    u.variants.edges[0].node.id,
                                                    savings,
                                                    i
                                                )
                                            }
                                            disabled={u.is_loading}
                                        >
                                            {u.is_loading ? (
                                                <Fragment>
                                                    <Loader
                                                        type="TailSpin"
                                                        color="#152622"
                                                        height={16}
                                                        width={16}
                                                    />
                                                    Adding...
                                                </Fragment>
                                            ) : (
                                                'Buy Now'
                                            )}
                                        </RedButton>
                                    )}
                                </FlexBox>
                            );
                        })}
                    </ul>
                )}
            </div>
        );
    }
    return <div />;
};

export default OneClickUpsells;
